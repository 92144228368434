import { Link, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import styles from './topbar.module.scss';
import useScreenSize from '../../../hooks/useScreenSize';

interface ITopbarProps {
    logo: string;
    linkLabel: string;
    link: string;
}

const Topbar = ({ logo, linkLabel, link }: ITopbarProps) => {
    const screenSize = useScreenSize();
    return (
        <Toolbar className={styles.m_topbar}>
            <Link href={link} underline="none">
                <img
                    src={logo}
                    alt={linkLabel}
                    className={styles.m_topbar__logo}
                />
            </Link>
            {screenSize > 968 && (
                <Link
                    href={link}
                    underline="none"
                    className={styles.m_topbar__link}
                >
                    <Typography variant="body1">
                        {linkLabel} <i className="icon-arrow-right" />
                    </Typography>
                </Link>
            )}
        </Toolbar>
    );
};

export default Topbar;
