import React from 'react';
import Footer from '../components/molecules/footer/footer';
import HeadboardAniversario from '../components/organisms/headboard-aniversario/headboardAniversario';
import headboardAniversarioAdapter from '../components/organisms/headboard-aniversario/adapter/headboardAniversario.adapter';
import Topbar from '../components/molecules/topbar/topbar';
import SEO from '../shared/seo';
import { ReferenciaContenido } from '../interfaces/ReferenciaContenido';
import LibraryComponents from '../components/templates/library-components/libraryComponents';
import Loading from '../components/atoms/loading/loading';

const TemplateAniversario = (props: any) => {
    return (
        <div style={{ overflow: 'hidden' }}>
            <SEO
                title={props.pageContext.metaTitulo}
                description={
                    props.pageContext.metaDescripcion.json.content[0].content[0]
                        .value
                }
                noIndex={props.pageContext.noIndex}
                urlCanonical={props.pageContext.urlCanonical}
                fbId={process.env.FACEBOOK_APP_ID_HOME}
            />
            <Topbar
                logo={
                    'https://images.ctfassets.net/gkhyeghj07ak/38O44ZEBecqm7PU7oP8iGp/9e0fc999340233038f84d36d5e2bf6e4/magenta.svg'
                }
                linkLabel={'Ir a Comfama.com'}
                link={'https://www.comfama.com/'}
            />
            <HeadboardAniversario
                {...headboardAniversarioAdapter(
                    props.pageContext.referenciaCabecera,
                )}
            />
            {props.pageContext.referenciaContenido ? (
                props.pageContext.referenciaContenido.map(
                    (value: ReferenciaContenido, index: number) => (
                        <LibraryComponents
                            key={index}
                            content={value}
                            categoryName={props.pageContext.nombreCategoria}
                        />
                    ),
                )
            ) : (
                <Loading />
            )}
            <Footer isSuperFooter={false} />
        </div>
    );
};

export default TemplateAniversario;
